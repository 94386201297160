.shareMyCart {
  @apply absolute z-10 inset-0 bg-ecru transform transition-transform duration-300;
  --tw-translate-x: 100%;
  visibility: hidden;
  transition: transform 0.3s 0s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s linear;
}
.shareMyCartOpen {
  transition: transform 0.3s 0s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0s linear;
  --tw-translate-x: 0;
  visibility: visible;
}
