.quantity {
  @apply h-24 px-3 flex items-center;
  min-width: 80px;
}

.actions {
  @apply flex p-1 items-center justify-center w-16 h-16 text-black;
  transition-property: border-color, background, color, transform, box-shadow;

  transition-duration: 0.15s;
  transition-timing-function: ease;
  user-select: none;
}

.actions:hover {
  @apply border  text-light;
  transition: border-color;
  z-index: 10;
}

.actions:focus {
  @apply outline-none;
}

.actions:disabled {
  @apply cursor-not-allowed;
}

.input {
  @apply bg-transparent text-12 text-center leading-20 px-4 w-full h-full focus:outline-none pointer-events-auto;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}