.component {
  --spasv-bg: #d3d7cf;
  --spasv--bar-bg: #e9ebe7;
  --spasv--bar-progress-bg: #000000;
  --spasv--sub-color: #434237;
  --spasv--tier-color: #434237;
  --spasv--next-tier-color: #000000;
  margin: 0 -20px 20px;
  padding: 12px 0 16px 0;
  background-color: var(--spasv-bg);
}

.howButton {
  @apply bg-sale text-white;
  font-size: 14px;
  font-weight: 400;
  padding: 0 10px;
  min-height: 36px;
  height: 36px;
  margin-bottom: 20px;
}

.howButtonIcon {
  font-weight: 700;
  margin-right: 8px;
  font-size: 17px;
}

.progressBarContainer {
  background-color: var(--spasv-bg);
  padding: 20px;
  overflow: hidden;
  width: 100%;
}

.progressBarBg {
  @apply absolute left-0;
  height: 12px;
  border-radius: 2px;
  top: 22px;
  width: 100%;
}

.progressBarProgress {
  background-color: var(--spasv--bar-progress-bg);
  height: 100%;
  border-radius: 6px;
  transition: width 0.6s ease-out;
}

.progressBarValuesContainer {
  background-color: var(--spasv--bar-bg);
  height: 12px;
  border-radius: 6px;
  width: 100%;
  position: relative;
}
.progressBarValue:last-child {
  background-color: var(--spasv-bg) !important;
  left: 100%;
}

.progressBarValue {
  position: absolute;
  line-height: 20px;
  height: 12px;
  width: 1px;
  top: 0px;
  left: var(--spasv-value-position);
  background-color: var(--spasv-bg);
}

.progressBarAmount,
.progressBarDiscount {
  @apply absolute font-g-medium font-medium;
  display: block;
  line-height: 100%;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  color: var(--spasv--sub-color);
  font-size: 9px;
}

.progressBarDiscount {
  top: 18px;
  transform: translateX(-100%);
}
.progressBarAmount {
  top: -13px;
  transform: translateX(-100%);
  text-align: center;
}

.message {
  @apply font-g-bold font-bold;
  padding: 0 20px 0;
  font-size: 12px;
  line-height: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  overflow: auto;
  z-index: 9000;
}

.modalBg {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.modalContainer {
  width: 100%;
  max-width: 600px;
  min-height: 200px;
  background-color: #a4695c;
  position: relative;
  text-align: center;
  padding: 30px;
  color: white;
}

.modalContent h1,
.modalContent h2,
.modalContent h3,
.modalContent h4 {
  color: white;
}

.mocalClose {
  appearance: none;
  background: none;
  padding: 0;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalClose svg {
  display: block;
  fill: white;
  width: 16px;
  height: 16px;
}

.subMessage {
  @apply font-g-regular;
  color: var(--spasv--sub-color);
  padding: 4px 20px 20px;
  line-height: 100%;
  font-size: 9px;
}

.progressBarDot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--spasv--bar-progress-bg);
  position: absolute;
  left: 0; /* This ensures the dot starts at the beginning */
  bottom: 0;
}
