.root {
  @apply cursor-pointer inline-flex 
  py-14 px-30 text-14 leading-20 transition ease-in-out duration-150 rounded-full
   text-center justify-center
  border border-transparent items-center font-g-medium
  max-h-48;
  letter-spacing: 8%;
  font-weight: 500;
}

/* .root:hover {
  @apply opacity-80;
} */

.root:focus {
  @apply outline-none;
}

.root:focus-visible {
  @apply shadow-outline-normal outline-none;
}

/* .root[data-active] {
  @apply bg-primary;
} */

.primary {
  @apply bg-black text-white uppercase tracking-spacingWidest;
}
.primary:hover {
  @apply bg-almostBlack;
}

.primaryDark {
  @apply bg-white text-black uppercase tracking-spacingWidest;
}

.primaryDark:hover {
  @apply bg-warmWhite;
}

.secondary {
  @apply bg-transparent text-black border-black uppercase px-30 tracking-spacingWidest;
}
.secondary:hover {
  @apply border-charcoal text-charcoal;
}

.secondaryLight {
  @apply bg-black text-white border-white uppercase px-30 tracking-spacingWidest;
}
.secondaryLight:hover {
  @apply bg-transparent;
}

.secondaryDark {
  @apply bg-transparent text-white border-white uppercase px-30 tracking-spacingWidest;
}
.secondaryDark:hover {
  @apply border-warmWhite text-warmWhite;
}

.tertiary {
  @apply bg-mocha text-white uppercase tracking-spacingWidest;
}
.tertiary:hover {
  @apply bg-terracotta;
}

.tertiaryDark {
  @apply bg-mocha text-white uppercase tracking-spacingWidest;
}
.tertiaryDark:hover {
  @apply bg-terracotta;
}

.buttonLink {
  @apply border-none text-black uppercase tracking-spacingWidest;
  letter-spacing: 1.5px;
}

.buttonLink:hover {
  @apply text-charcoal;
}

.buttonLink::after {
  content: url('/button-caret-black.svg');
  padding-left: 12px;
  margin-top: 2px;
}

.buttonLink::after:hover {
  content: url('/button-caret-grey.svg');
}

.buttonLinkDark {
  @apply border-none text-white uppercase;
}

.buttonLinkDark:hover {
  @apply text-midGrey;
}

.buttonLinkDark::after {
  content: url('/button-caret-white.svg');
  padding-left: 12px;
  margin-top: 2px;
}

.buttonLinkDark::after:hover {
  content: url('/button-caret-grey.svg');
}

.buttonLinkLight {
  @apply border-none text-white uppercase;
}

.buttonLinkLight:hover {
  @apply text-midGrey;
}

.buttonLinkLight::after {
  content: url('/button-caret-white.svg');
  padding-left: 12px;
  margin-top: 2px;
}

.buttonLinkLight::after:hover {
  content: url('/button-caret-grey.svg');
}

.textLink {
  @apply border-none text-black underline;
}

.textLink:hover {
  @apply text-charcoal;
}

.textLinkDark {
  @apply border-none text-white underline;
}

.textLinkDark:hover {
  @apply text-midGrey;
}

.loading {
  @apply cursor-not-allowed;
}

.small {
  @apply px-16 py-8 max-h-28 text-12 leading-16;
}

.disabled {
  @apply cursor-not-allowed;
}

.primaryDisabled {
  @apply bg-doveGrey;
}

.secondaryDisabled {
  @apply border-doveGrey text-doveGrey;
}

.tertiaryDisabled {
  @apply bg-doveGrey;
}

.buttonLinkDisabled,
.textLinkDisabled {
  @apply text-doveGrey;
}

.primaryDarkDisabled {
  @apply bg-doveGrey text-white;
}

.secondaryDarkDisabled {
  @apply border-doveGrey text-doveGrey;
}

.tertiaryDarkDisabled {
  @apply bg-doveGrey text-white;
}

.buttonLinkDarkDisabled,
.textLinkDarkDisabled {
  @apply text-charcoal;
}

.login {
  @apply bg-doveGrey text-white uppercase;
}

.noPad {
  @apply p-0;
}
