.root {
  @apply fixed bg-black bg-opacity-50 flex items-center inset-0 z-50 justify-center;
  /* backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px); */
}

.modal {
  @apply bg-ecru p-12 border relative border-none;
}

.modal:focus {
  @apply outline-none;
}

.close {
  @apply hover:text-light transition ease-in-out duration-150 focus:outline-none absolute right-12 top-12 m-2;
}
