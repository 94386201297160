.root {
  @apply flex flex-col pb-24;
}

.root:first-child {
  padding-top: 0;
}

.root del {
  @apply text-charcoal;
}

.productImage {
  position: absolute;
  width: 72px;
  height: 72px;
  z-index: 1;
}

.productName {
  @apply font-g-bold text-12 leading-20 cursor-pointer pb-1 m-0;
}

.productDesc {
  @apply font-g-regular text-12 leading-20 cursor-pointer pb-1 m-0;
}

.quantityPrice {
  @apply flex justify-between flex-wrap;
}

.quantityPrice > div {
  @apply mt-8;
}

.remove {
  @apply p-0 text-doveGrey absolute top-0 right-0 top-5;
}
