.root {
  @apply flex flex-col gap-y-8 p-12 mb-24 rounded-2 tracking-none relative;
  background-color: var(--addon-bg);
}

.light {
  color: #ffffff;
}

.dark {
  color: #000000;
}

.thumb {
  @apply w-72 h-72 flex-shrink-0;
}

.content {
  @apply flex gap-x-12 gap-y-8 items-center;
}

.addonLineItemError {
  color: var(--addon-line-error-color);
}

.details :global .wysiwyg p,
.details :global .wysiwyg strong {
  @apply text-12 leading-20 tracking-none;
}

.details :global .wysiwyg a,
.details :global .wysiwyg a:hover {
  color: currentColor;
}

.price,
.added,
.addonLineItemError {
  @apply text-12 leading-20;
}

.price :global .text-sale {
  color: currentColor;
}

.added {
  @apply flex items-center gap-x-4;
  color: var(--addon-added-color);
}

.added svg {
  fill: currentColor;
}
.dark .errorMessage {
  color: var(--addon-incomp-error-color);
}

.addonError {
  padding: 10px 16px;
  background: var(--addon-error-bg-color);
  text: var(--addon-error-text-color);
}

.button {
  @apply max-h-32 w-full h-32;
}

.sizeOnlybutton {
  @apply h-34;
  max-height: 34px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 100px;
}

.optionContainer {
  @apply py-8 flex gap-8;
}
