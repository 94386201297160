.root {
  @apply absolute flex bg-opacity-50 transition-visibility duration-150 ease-linear;
  z-index: 100;
}

.overlay {
  @apply w-full h-full;
  /* backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px); */
}

.sidebar {
  @apply fixed;
  transition: transform 450ms cubic-bezier(0.355, 0.51, 0.235, 0.925) 0s;
}

.close {
  @apply hover:text-light transition ease-in-out duration-150 focus:outline-none absolute right-12 top-12 m-2;
}
