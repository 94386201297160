.root {
  min-height: 100vh;
}

.root.empty {
  @apply bg-white text-black;
}

.lineItemsList {
  @apply p-0 m-0;
}

.miniCartSidebar {
  @apply flex bg-ecru;
  max-width: 375px;
  width: calc(100vw - 50px);
}

.container {
  @apply flex flex-grow flex-col justify-between items-stretch;
  max-width: 100%;
}

.header {
  @apply flex px-20 py-24 items-end justify-between;
  padding-right: 140px;
}

.closeButton {
  @apply flex flex-auto items-center justify-center absolute top-15 -left-50 w-50 h-50;
}

.closeButton svg {
  @apply block w-22 h-22 fill-current text-white;
}

.content {
  @apply flex-grow overflow-y-scroll px-20 scrollbar-hide;
}

.footer {
  @apply px-20;
}

.overflowing {
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.08);
}
